import React from 'react'
import { motion } from 'framer-motion'
import { fadeIn } from '../../utils/motion'

function ClientSectionTH() {
  return (
    <>
        <motion.section initial="hidden"
        whileInView="show"
        viewport={{ once: false, amount: 0.25 }} id="client" className="client">
            <div className="container col-12">
                <div className="clientContent clientContentTH">
                    <motion.div variants={fadeIn("down", "tween", 1.5, 2)} className="col-12 clientHeader">
                        <h2>ลูกค้าของเรา</h2>
                    </motion.div>
                    <motion.div variants={fadeIn("in", "tween", 2, 2)} className="clientItem">
                        <div className="clientImg"><img src="/images/ais.webp"alt="Bigfish Digital"/></div>
                        <div className="clientImg"><img src="/images/jysk.webp" alt="Bigfish Digital"/></div>
                        <div className="clientImg"><img src="/images/olay.webp" alt="Bigfish Digital"/></div>
                        <div className="clientImg"><img src="/images/ikea.webp" alt="Bigfish Digital"/></div>
                        <div className="clientImg"><img src="/images/epson.webp" alt="Bigfish Digital"/></div>
                        <div className="clientImg"><img src="/images/ananda.webp" alt="Bigfish Digital"/></div>
                    </motion.div>
                </div>
            </div>
        </motion.section>
    </>
  )
}

export default ClientSectionTH