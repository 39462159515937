import React from 'react'
import { motion } from 'framer-motion'
import { fadeIn } from '../../utils/motion'
import ('./About.css')

function About() {
  return (
    <>
        <motion.section initial="hidden"
        whileInView="show"
        viewport={{ once: false, amount: 0.25 }} id="about" className="about">
            <div className="container col-12">
                <div className="about_content">
                    <motion.div variants={fadeIn("down", "tween", 1, 1.8)} className="aboutLogo">
                        <img src="/images/B_logo2.webp" alt="Bigfish digital"/>
                    </motion.div>
                    <motion.div variants={fadeIn("down", "tween", 1.5, 1.8)} className="aboutText">
                        <p>
                            At BIGFISH Digital, our clients are our top most priority! 
                            We believe that a good marketing plan, 
                            branding and awareness will positively 
                            impact your company’s growth and reputation.
                        </p>
                        <p>
                            BIGFISH Digital offers a wide range of services to suit the needs of our clients. 
                            With our unique approach for marketing; Long-Short Strategy. It will cover 
                            Branding, Sales and Awareness for our clients.
                        </p>
                        <p>
                            We are dedicated to share with you our unique approach and how it 
                            would help in strengthening your business. We pride ourselves with our 
                            knowledge and experiences for more than 20 years. The greatest satisfaction is 
                            knowing that we have been a part of your company’s growth.
                        </p>
                    </motion.div>
                    <motion.div variants={fadeIn("down", "tween", 2, 1.8)} className="aboutButton">
                        <a href="#contact"><span>LET'S WORK TOGETHER</span></a>
                    </motion.div>
                </div>
            </div>
        </motion.section>
    </>
  )
}

export default About