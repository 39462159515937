import React from 'react'
import './Wantoget.css'
import { VideoSitting } from '../../utils/Sitting'

function Wantoget() {
  return (
    <>
        <section className="wantToget">
            <video {...VideoSitting} className="wtVideo">
                <source src='images/bg_video2.mp4' type='video/mp4'/>
            </video>
            <div className="container col-12">
                <div className="wtContent">
                    <span>“Want To Get Started On Building A Strong Online Presence?</span><br></br>
                    <span>Contact Us And We Will Share With You Our Digital Strategies”</span>
                </div>
            </div>
        </section>
    </>
  )
}

export default Wantoget