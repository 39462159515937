export const  VideoSitting= {
    autoPlay:true,
    loop:true,
    muted:true,
    playsInline :true,

    responsive: [
        {
            breakpoint: 1024,
            settings: {
                autoplay:false,
                loop:false,
                muted:true,
                playsinline:true,
            },
        },
        {
            breakpoint: 768,
            settings: {
                autoplay:false,
                loop:false,
                muted:true,
                playsinline:true,
            },
        },
        {
            breakpoint: 480,
            settings: {
                autoplay:false,
                loop:false,
                muted:true,
                playsinline:true,
            },
        },
    ],
};