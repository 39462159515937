import About from "../component/about/About";
import BacktoTop from "../component/backtotop/BacktoTop";
import ClientSection from "../component/clientsSection/ClientSection";
import ContactSection from "../component/contact/ContactSection";
import Feature from "../component/feature/Feature";
import Googlemap from "../component/googlemap/Googlemap";
import Header from "../component/header/Header";
import Nav from "../component/nav/Nav";
import Wantoget from "../component/wantToGet/Wantoget";

function Engpage() {
  return (
    <>
      <Nav/>
      <Header/>
      <Feature/>
      <About/>
      <Wantoget/>
      <ClientSection/>
      <ContactSection/>
      <Googlemap/>
      <BacktoTop/>
    </>
  );
}

export default Engpage;
