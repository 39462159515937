import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { fadeIn } from '../../utils/motion'
import { VideoSitting } from '../../utils/Sitting'
import ('./Header.css')

function Header() {
  return (
    <>
        <motion.header 
        initial="hidden"
        whileInView="show"
        viewport={{ once: false, amount: 0.25 }}
        className='header'>
            <video {...VideoSitting} className='bg_video'>
                <source src='images/bg_video.mp4' type='video/mp4'/>
            </video>
            <div className='translate'>
                <div class="dropdown">
                    <a class="btn btn-secondary dropdown-toggle" href="/#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        EN
                    </a>
                    <ul class="dropdown-menu">
                        <li><Link className="dropdown-item" to="/th">TH</Link></li>
                    </ul>
                </div>
            </div>
            <motion.div variants={fadeIn("down", "tween", 1, 1.8)} className='container col-12'>
                <div className='header_content'>
                    <h1>full service digital agency</h1>
                    <h2>We craft beautiful digital marketing tactics that<br></br>grow your businesses. </h2>
                </div>
                <div className='header_btn'>
                    <a href='#contact'><span>Get FREE Consultation &gt; </span></a>
                </div>
            </motion.div>
        </motion.header>
    </>
  )
}



export default Header