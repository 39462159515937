import React, { useEffect, useState } from 'react'
import './BacktoTop.css'

function BacktoTop() {
    const [backtotop , setBackToTop] = useState(false);

    useEffect (()=>{
        window.addEventListener("scroll", () => {
            if(window.scrollY > 200) {
                setBackToTop(true)
            } else {
                setBackToTop(false)
            }
        })
    },[])

    const scrollUp = () => {
        window.scrollTo ({
            top:0 ,
            behavior: "smooth"
        })
    }
    return (
        <div>
        {backtotop && (
            <a href="#" className="btn btn-primary p-2 back-to-top" onClick={scrollUp}>
                <i className="fa fa-angle-double-up"></i>
            </a>
        )}
        </div>
    )
}

export default BacktoTop