import React, { useRef } from 'react'
import emailjs from '@emailjs/browser';
import { motion } from 'framer-motion'
import { fadeIn } from '../../utils/motion'
import './contactSection.css'
import { VideoSitting } from '../../utils/Sitting';

function ContactSection() {
    const form = useRef();

    const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
    'service_5zf6zwq', 
    'template_z0lwrxh', 
    form.current, 
    'vTMG6ik4BADlMUJtJ')
        .then((result) => {
            console.log(result.text);
            console.log("message sent");
            e.target.reset();
        }, (error) => {
            console.log(error.text);
        });
    };

    return (
        <>
            <motion.section
            initial="hidden"
            whileInView="show"
            viewport={{ once: false, amount: 0.25 }}
            id="contact" className="contact" >
                <video {...VideoSitting} className="contactVideo">
                    <source src='images/bg_video3.mp4' type='video/mp4'/>
                </video>
                <div className="container col-12">
                    <div className="contactCT">
                        <motion.h2 
                            variants={fadeIn("down", "tween", 1, 1.8)} 
                            className="col-12">LET'S CONNECT</motion.h2>
                        <div className="content">
                            <motion.div 
                            variants={fadeIn("down", "tween", 1.5, 1.8)}
                            className="information col-12 col-lg-4 col-md-12">
                                <div className="phone">
                                    <p>PHONE</p>
                                    <span><a href="tel:+66969966505">+6696-996-6505</a></span><br></br> 
                                    <span>contact@bigfishdigital.co.th</span>
                                </div>
                                <div className="address">
                                    <p>ADDRESS</p>
                                    <span>25 Alma Link Building<br></br> 
                                        Floor 17th, Room 424, Soi Chidlom, <br></br> 
                                        Ploenchit Road, Lumpini,<br></br>  
                                        Pathumwan Bangkok 10330</span>
                                    
                                </div>
                            </motion.div>

                            <motion.form 
                            variants={fadeIn("in", "tween", 2, 1.8)}
                            ref={form} onSubmit={sendEmail}
                            className="contactForm col-12 col-lg-7  col-md-12 ">
                                <div className="formcontent">
                                    <div className="formName col-12">
                                        <input className="col-12 col-md-12 col-lg-6" name="name" type="text" placeholder="Name"  required maxlength="100" aria-required="true" aria-invalid="true"></input>
                                        <input className="col-12 col-md-12 col-lg-6" name="email" type="email" placeholder="Email" required  maxlength="200" aria-label="Email" aria-required="true" aria-invalid="true"></input>
                                    </div>
                                    <input type="tel" name="tel" placeholder="Phone" className="col-12" aria-required="false" maxlength="20"></input>
                                    <textarea className="col-12" placeholder="Type your message here..." aria-required="false" aria-invalid="true" name="message"></textarea>
                                    <button className="formBtn btn btn-danger text-center" type="submit" value="Send">Submit</button>
                                </div>
                            </motion.form>
                        </div>
                    </div>
                </div>
            </motion.section>
        </>
    )
}

export default ContactSection