import React from 'react'
import { motion } from 'framer-motion'
import { fadeIn } from '../../utils/motion'

function AboutTH() {
  return (
    <>
        <motion.section initial="hidden"
        whileInView="show"
        viewport={{ once: false, amount: 0.25 }} id="about" className="about">
            <div className="container col-12">
                <div className="about_content">
                    <motion.div variants={fadeIn("down", "tween", 1.5, 2)} className="aboutLogo">
                        <img src="/images/B_logo2.webp" alt="Bigfish Digital"/>
                    </motion.div>
                    <motion.div variants={fadeIn("down", "tween", 2, 2)} className="aboutText aboutTextTH">
                        <p>
                            ที่ BIGFISH Digital ลูกค้า คือ สิ่งสำคัญที่สุดของเรา! เราเชื่อว่าแผนการตลาด การสร้างแบรนด์ และการรับรู้ที่ดีจะส่งผลดีต่อการเติบโตและชื่อเสียงของบริษัทของคุณ
                        </p>
                        <p>
                        ที่ BIGFISH Digital เรานำเสนอบริการที่หลากหลายเพื่อตอบสนองความต้องการของลูกค้าของเรา ด้วยแนวทางการตลาดที่ไม่เหมือนใคร และกลยุทธ์ที่หลากหลายทั้งแบบระยะสั้นและระยะยาว ซึ่งครอบคลุมในทุกๆ ด้านตั้งแต่ด้านสร้างแบรนด์ การขาย และการเพิ่มการรับรู้ ฯลฯ
                        </p>
                        <p>
                        ที่ BIGFISH Digital เราทำงานอย่างทุ่มเทเพื่อช่วยแนะนำกลยุทธ์การตลาดของเรากับคุณ เพื่อให้ธุรกิจของคุณแข็งแกร่งขึ้น เราภาคภูมิใจในความรู้และประสบการณ์ของเรามากกว่า 20 ปีในวงการดิจิตัลและมีเดีย และความพึงพอใจสูงสุดของเรา คือ การที่เราได้เป็นส่วนหนึ่งของการเติบโตของบริษัทคุณ
                        </p>
                    </motion.div>
                    <motion.div variants={fadeIn("down", "tween", 2.4, 2)} className="aboutButton">
                        <a href="#contact"><span>ลงทะเบียนปรึกษา FREE &gt; </span></a>
                    </motion.div>
                </div>
            </div>
        </motion.section>
    </>
  )
}

export default AboutTH