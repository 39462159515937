import React from 'react'
import Googlemap from '../component/googlemap/Googlemap'
import BacktoTop from '../component/backtotop/BacktoTop'
import NavTH from '../component/thpage/NavTH'
import HeaderTH from '../component/thpage/HeaderTH'
import FeatureTH from '../component/thpage/FeatureTH'
import AboutTH from '../component/thpage/AboutTH'
import WantogetTH from '../component/thpage/WantogetTH'
import ClientSectionTH from '../component/thpage/ClientSectionTH'
import ContactSectionTH from '../component/thpage/ContactSectionTH'

function Thaipage() {
  return (
    <>
      <NavTH/>
      <HeaderTH/>
      <FeatureTH/>
      <AboutTH/>
      <WantogetTH/>
      <ClientSectionTH/>
      <ContactSectionTH/>
      <Googlemap/>
      <BacktoTop/>
    </>
  )
}

export default Thaipage