import React from "react";

function Googlemap() {
  return (
    <>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3875.557515640635!2d100.5444662!3d13.7452183!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29fff89db31d1%3A0xc55caa8b84fa547a!2sBigfish%20Digital%20Marketing%20Agency!5e0!3m2!1sth!2sth!4v1695959134079!5m2!1sth!2sth"
        width="100%"
        height="450"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
      <div className="copyRight bg-dark col-12 text-center text-white p-3 ">
        <p>© 2022 BIGFISH DIGITAL. ALL RIGHTS RESERVED</p>
      </div>
    </>
  );
}

export default Googlemap;
