import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { fadeIn } from '../../utils/motion'
import { VideoSitting } from '../../utils/Sitting'

function HeaderTH() {
  return (
    <>
        <motion.header 
        initial="hidden"
        whileInView="show"
        viewport={{ once: false, amount: 0.25 }}
        className='header'>
            <video {...VideoSitting} className='bg_video'>
                <source src='images/bg_video.mp4' type='video/mp4'/>
            </video>
            <div className='translate'>
                <div class="dropdown">
                    <a class="btn btn-secondary dropdown-toggle" href="/#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        TH
                    </a>
                    <ul class="dropdown-menu">
                        <li><Link className="dropdown-item" to="/">EN</Link></li>
                    </ul>
                </div>
            </div>
            <motion.div variants={fadeIn("down", "tween", 1.5, 2)} className='container col-12'>
                <div className='header_content headerTH'>
                    <h1>เราคือมีเดียเอเจนซี่รับทำการตลาดออนไลน์ทุกรูปแบบ</h1>
                    <h1>ด้วยประสบการณ์มากกว่า 20 ปี</h1>
                    <h2>"เราออกแบบกลยุทธ์การตลาดที่หลากหลายเพื่อช่วยให้ธุรกิจของคุณเติบโตอย่างแข็งแกร่ง"</h2>
                </div>
                <div className='header_btn'>
                    <a href='#contact'><span>ลงทะเบียนปรึกษา FREE &gt; </span></a>
                </div>
            </motion.div>
        </motion.header>
    </>
  )
}



export default HeaderTH