import React, { useEffect, useState } from 'react'

function NavTH() {

    const [burger_class, setBar] = useState("burger-bar  unclicked");
    const [menu_list, setNavList] = useState("menu hidden");
    const [isMenuClicked, setIsMenuClicked] = useState(false);

    const updateMenu = () => {
    if (!isMenuClicked) {
        setBar("burger-bar clicked");
        setNavList("menu visible");
    } else {
        setBar("burger-bar unclicked");
        setNavList("menu hidden");
    }
    setIsMenuClicked(!isMenuClicked);
    };

    const [Navbg, setNavBg] = useState("#fff");
    const [Logohide, setLogoHide] = useState("1")
    const [Navshadow, setNavShadow] = useState ("inset")
    const [Logomsize, setLogomSite] = useState (" ")
    const listenScrollEvent = () => {
        window.pageYOffset > 300 ? setNavBg("url(./images/navbgeffect.png)") : setNavBg("#fff");
        window.pageYOffset > 300 ? setLogoHide("0") : setLogoHide("");
        window.pageYOffset > 300 ? setNavShadow("none") : setNavShadow(" ");
        window.pageYOffset > 300 ? setLogomSite("0") : setLogomSite("");
    };
    useEffect(() => {
        window.addEventListener("scroll", listenScrollEvent);
    return () => {
            window.removeEventListener("scroll", listenScrollEvent);
        };
    }, []);
    return (
        <>
            <div className="navbar" style={{
                background:Navbg,
                boxShadow:Navshadow,
                backgroundImage:Navbg,
                position:'fixed',
                transition: "all .5s"
              }}>
                <div className="nav_logo" style={{
                    opacity:Logohide,
                    margin:Logomsize,
                    transition: "all .3s"

                    }}>
                    <div className="b_logo">
                        <a href="/"><img src="/images/B_logo.webp" alt="Bigfish Digital"/> </a>                   
                    </div>
                    <div className="font_logo">
                        <a href="/"><img src="/images/font_logo.webp" alt="Bigfish Digital"/></a>
                    </div>
                </div>
                <div className="nav">
                    <div className="desktop_menu">
                        <ul className="dmn_list navTH">
                            <li className="menu_item"><a href="/th">หน้าหลัก</a></li>
                            <li className="menu_item"><a href="#service">บริการ</a></li>
                            <li className="menu_item"><a href="#about">เกี่ยวกับ</a></li>
                            <li className="menu_item"><a href="#client">ลูกค้า</a></li>
                            <li className="menu_item"><a href="#contact">ดิดต่อ</a></li>
                        </ul>
                    </div>
                    <div className="buger-menu" onClick={updateMenu}>
                        <div className={burger_class} ></div>
                        <div className={burger_class} ></div>
                        <div className={burger_class} ></div>
                    </div>
                    <ul id="navTH" className={menu_list}>
                        <li className="menu_item" onClick={updateMenu}><a href="/th">หน้าหลัก</a></li>
                        <li className="menu_item" onClick={updateMenu}><a href="#service">บริการ</a></li>
                        <li className="menu_item" onClick={updateMenu}><a href="#about">เกี่ยวกับ</a></li>
                        <li className="menu_item" onClick={updateMenu}><a href="#client">ลูกค้า</a></li>
                        <li className="menu_item" onClick={updateMenu}><a href="#contact">ดิดต่อ</a></li>
                    </ul>
                </div> 
                    
            </div>
        </>
  )
}

export default NavTH