import React from 'react'
import { motion } from 'framer-motion'
import { fadeIn } from '../../utils/motion'
import './Feature.css'

function Feature() {
  return (
    <>
        <motion.section 
        initial="hidden"
        whileInView="show"
        viewport={{ once: false, amount: 0.25 }} id="service" className="feature">
            <div className="container col-12">
                <div className="content">
                    <motion.div variants={fadeIn("in", "tween", 1, 1.8)} className="feature-title">
                        <h2>Featured<br></br>
                        Services</h2>
                    </motion.div>
                    <div className="feature_content">
                        <div className="topContent ">
                            <div className="content-item">
                                <span>Media<br></br>buying</span>
                                <ul>
                                    <li>Push Ad</li>
                                    <li>Native Ad</li>
                                    <li>Video Ad</li>
                                </ul>
                            </div>
                            <div className="content-item">
                                <span>Content<br></br>Marketing</span>
                                <ul>
                                    <li>Online PR News</li>
                                    <li>Adventorial</li>
                                    <li>Content SEO</li>
                                </ul>
                            </div>
                            <div className="content-item">
                                <span>Facebook<br></br>Instagram</span>
                                <ul>
                                    <li>Brand Awareness</li>
                                    <li>Conversions</li>
                                    <li>App Installs</li>
                                </ul>
                            </div>
                        </div>
                        <div className="topBottom ">
                            <div className="content-item">
                                <span>Google<br></br>Youtube</span>
                                <ul>
                                    <li>SEM</li>
                                    <li>GDN</li>
                                    <li>Youtube Ad</li>
                                </ul>
                            </div>
                            <div className="content-item">
                                <span>Line<br></br>OA</span>
                                <ul>
                                    <li>Line Followers</li>
                                    <li>Line Ad Platform</li>
                                    <li>Line Chat Bot</li>
                                </ul>
                            </div>
                            <div className="content-item">
                                <span>Other<br></br>Services</span>
                                <ul>
                                    <li>Tiktok</li>
                                    <li>Twitter</li>
                                    <li>SMS Marketing</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </motion.section>
    </>
  )
}

export default Feature